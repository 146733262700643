export default {
  "app-title": "FOREX IFC",
  "app-title-2": "Nepřístupný",
  "app-title-language": "Jazyk",
  "title-detail": "Detail",
  "title-welcome_to_use": "Vítejte k použití",

  // tabBar
  "tabbar-market": "Trh",
  "tabbar-order": "Objednávka",
  "tabbar-news": "Zprávy",
  "tabbar-mine": "Můj účet",
  "public-night_mode": "Noční režim",
  "public-noRecord": "Zatím žádná data",
  "public-text-cancel": "Zrušit",
  "public-text-confirm": "Potvrdit",
  "public-text-balance": "Zůstatek",
  "public-text-date": "Datum",
  "public-text-completed": "Dokončeno",
  "public-text-invalid": "Neplatné",
  "public-text-unchecked": "Nezkontrolované",
  "public-text-remarks": "Poznámka",
  "public-text-loading": "Načítání",
  "public-text-loose-to-refresh": "Uvolněte pro obnovení...",
  "public-text-buy": "Koupit",
  "public-text-sell": "Prodat",
  "public-text-close": "Zavřeno",
  "public-text-lots_1": "Lóty",
  "public-text-lots": "Lóty",
  "public-text-available": "K dispozici",
  "public-text-freeze": "Zmrazeno",
  "public-text-scroll_down_refresh": "Přetáhněte dolů pro obnovení...",
  "public-btn-submit": "Odeslat",
  "public-btn-add": "Přidat",
  "public-btn-copy": "Kopírovat",
  "public-btn-delete": "Smazat",
  "public-btn-copyLink": "Kopírovat odkaz na pozvánku",
  "public-btn-send": "Odeslat",
  "public-btn-download": "STAŽENÍ",
  "public-btn-resetPassword": "Obnovit heslo",
  "public-btn-confirmation": "Potvrzení",
  "public-btn-accept": "Přijmout",
  "public-enterEmail": "Zadejte svůj email",
  "public-password": "Heslo",
  "public-retypePassword": "Znovu zadejte heslo",
  "public-inviteCode": "Kód pozvánky",
  "public-verifyCode": "Ověřovací kód",
  "login-newUser": "Nový uživatel?",
  "login-joinNow": "Přidejte se nyní",
  "login-forgotPassword": "Zapomenuté heslo",
  "login-btn-login": "Přihlásit se",
  "login-btn-demoAcc":
    "Není třeba otevírat účet, vytvořte si demo účet a obchodujte nyní!",
  "login-enterEmail": "Zadejte prosím svou emailovou adresu",
  "login-enterPassword": "Zadejte prosím své heslo",
  "regsiter-btn-register": "Zaregistrujte se",
  "regsiter-enterInviteCode": "Zadejte prosím kód pozvánky",
  "regsiter-enterVerityCode": "Zadejte prosím ověřovací kód",
  "logout-title": "Odhlásit se",
  "systemHint-reset_successful": "Úspěšně obnoveno",
  "systemHint-incorrectVerityCode": "Nesprávný ověřovací kód",
  "systemHint-passwordsDoNotMatch": "Hesla se neshodují",
  "systemHint-password_error": "Chyba hesla",
  "systemHint-login_connot_find_user": "Nelze najít tohoto uživatele",
  "systemHint-copySuccess": "Úspěšně zkopírováno",
  "systemHint-plsCompleted": "Vyplňte prosím všechny údaje",
  "systemHint-pwdInconsistent": "Heslo se neshoduje",
  "systemHint-incorrectVerifyCode": "Nesprávný ověřovací kód",
  "systemHint-changeSuccess": "Úspěšně změněno",
  "systemHint-plsSubmit": "Odešlete prosím úplné informace",
  "systemHint-binb-bank-not-open-yet": "Výběr této měny ještě není dostupný",
  "systemHint-plsFillIn": "Vyplňte prosím všechna pole",
  "systemHint-requestSuccessful": "Žádost úspěšná",
  "systemHint-operation_successful": "Úspěšná operace",
  "systemHint-set_successful": "Úspěšně nastaveno",
  "systemHint-missing_params-or-val-error":
    "Chybí parametry nebo chyba při předávání hodnoty",
  "systemHint-limit_trade_price_must_greater":
    "Limitní cena obchodu musí být vyšší než 0",
  "systemHint-buy-stop_loss_price_higher_than_opening_price":
    "Cena stop loss při koupi (Long) nemůže být vyšší než otevírací cena",
  "systemHint-buy-stop_profit_price_higher_than_opening_price":
    "Cena stop profit při koupi (Long) nemůže být vyšší než otevírací cena",
  "systemHint-sell-stop_loss_price_lower_than_opening_price":
    "Cena stop loss při prodeji (Short) nemůže být nižší než otevírací cena",
  "systemHint-sell-stop_profit_price_lower_than_opening_price":
    "Cena stop profit při prodeji (Short) nemůže být nižší než otevírací cena",
  "systemHint-code_send_succesful": "Ověřovací kód úspěšně odeslán",
  "systemHint-pls_register_login_real_account":
    "Zaregistrujte se nebo přihlaste k reálnému účtu",
  "systemHint-successful_exit": "Úspěšně odhlášeno",
  "popupHint-set_up_collection_account": "Nejprve nastavte inkasní účet",
  "popupHint-confirm_to_delete": "Opravdu chcete smazat?",
  "popupHint-whether_return_it": "Chcete se vrátit?",
  "mine-availableAssets": "Dostupné prostředky",
  "mine-account_details": "Detaily účtu",
  "mine-deposit": "Vklad",
  "mine-withdraw": "Výběr",
  "mine-billDetails": "Detaily faktury",
  "mine-demo_return_simulated_account": "Návrat na simulovaný účet",
  "mine-wallet": "Peněženka",
  "mine-verifyName": "Ověření skutečného jména",
  "mine-inviteFriend": "Pozvat přátele",
  "mine-changePassword": "Změnit heslo",
  "mine-onlineSerive": "Online podpora",
  "mine-complaintEmail": "Email pro stížnosti",
  "mine-announcement": "Oznámení",
  "mine-establishment": "Nastavení",
  "mine-other": "Ostatní",
  "mine-change_theme": "Změnit téma",
  "dialog-title": "Výzva",
  "dialog-logout-conetnt": "Opravdu se chcete odhlásit?",
  "announce-text":
    "Z důvodu politických důvodů nejsou poskytovány služby Severní Koreji, Izraeli, Číně, Vanuatu a Kubě.",
  "announce-text-demo": "(FOREX IFC Jen pro testování)",
  "verify-text-upload": "Nahrajte prosím přední a zadní stranu vašeho pasu/OP",
  "verify-front": "Přední strana",
  "verify-front-desc": "Nahrajte přední stranu vašeho pasu/certifikátu",
  "verify-back": "Zadní strana",
  "verify-back-desc": "Nahrajte zadní stranu vašeho pasu/certifikátu",
  "verify-text-info": "Dokončete prosím své osobní údaje",
  "verify-name": "Jméno",
  "verify-enterName": "Zadejte prosím své jméno",
  "verify-passportId": "Číslo pasu/OP",
  "verify-enterPassportId": "Zadejte prosím číslo pasu/OP",
  "verify-infoAuth": "Audit",
  "wallet-bind-bank": "Připojit bankovní kartu",
  "wallet-bind-currency-address": "Připojit adresu digitální měny",
  "bind-bank-name": "Název banky",
  "bind-bank-address": "Indirizzo della banca",
  "bind-bank-swift": "SWIFT",
  "bind-bank-payee": "Příjemce",
  "withdraw-expected": "Odhadovaná částka připsaná na účet",
  "bind-bank-payee-account": "Účet příjemce",
  "bind-currency": "Měna",
  "bind-wallet-address": "Adresa peněženky",
  "deposit-digitai-currency": "Digitální měna",
  "deposit-bank-card": "Bankovní karta",
  "deposit-select_network": "Vyberte síť",
  "deposit-network_fee": "Síťový poplatek",
  "deposit-hint":
    "Odeslání jiné měny na výše uvedenou adresu může vést ke ztrátě prostředků",
  "deposit-copy_hint":
    "Výše uvedená adresa je zkopírována a vložena na platformu výběru k odeslání na váš účet",
  "deposit-copy_address": "Kopírovat adresu",
  "deposit-enter_bank_card": "Zadejte číslo bankovní karty",
  "deposit-bank_hint":
    "Nepodporujeme třetí strany ani mezibankovní platby, při vkládání prostředků zajistěte, aby se jméno na bankovním účtu shodovalo s vaším jménem",
  "bill-expenses": "Výdaje",
  "bill-income": "Příjmy",
  "bill-deposit": "Vklad",
  "bill-withdraw": "Výběr",
  "bill-transfer": "Převod",
  "bill-from": "Z",
  "bill-to": "Na",
  "bill-reminder": "Připomenutí",
  "bill-charge": "Poplatek",
  "bill-loan": "Půjčka",
  "bill-generate_time": "Čas generování",
  "bill-time": "Čas",
  "bill-fundsStatus": "Stav fondů",
  "bill-success": "Úspěšné",
  "bill-close": "Zavřít",
  "bill-expensesType": "Typ výdajů",
  "bill-incomeType": "Typ příjmů",
  "bill-transaction_type": "Typ transakce",
  "bill-order_no": "Číslo objednávky",
  "bill-note": "Poznámka",
  "bill-enter_bank_card": "Zadejte číslo bankovní karty",
  "bill-account": "Účet",
  "bill-currency": "Měna",
  "bill-balance": "Zůstatek",
  "bill-platformAddress": "Adresa platformy",
  "bill-platformName": "Název platformy",
  "mine-register": "Registrace",

  Res_Msg1: "Zadejte e-mail",
  Res_Msg2: "Neplatný formát e-mailu",
  Res_Msg3: "Zadejte heslo",
  Res_Msg4: "Heslo a opakované heslo se neshodují",
  Res_Msg5: "Délka hesla musí být alespoň 6 znaků",
  Res_Msg6: "Chybně zadaný ověřovací kód",
  Res_Msg7: "Zadejte ověřovací kód",
  Res_su: "Registrace byla úspěšná",
  Login_su: "Přihlášení bylo úspěšné",
  Loading: "Žádná další data",
  SDealCont_TopName: "Dodání",
  SDealCont_L1: "Nákupní cena",
  SDealCont_L2: "Aktuální cena",
  SDealCont_L3: "Směr",
  SDealCont_L4: "Množství",
  SDealCont_L5: "Čas dodání",
  SDealCont_btn: "Zavřít",
  SDealCont_L6: "Nákup dlouhý",
  SDealCont_L7: "Nákup krátký",
  SDealCont_L8: "Koupit",
  SDealCont_L9: "Prodat",
  "verify-verified": "Ověření bylo úspěšné",
  "verify-notVerified": "Ověření selhalo",
  "加载中...": "Načítání...",
  "分时":"Čas",
  "登入密碼": "Zadejte prosím heslo",
    "登入密碼不能为空": "Heslo nesmí být prázdné"
};
