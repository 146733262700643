export default {
    'app-title': 'FOREX IFC',
    'app-title-2': '판매 불가',
    'app-title-language': '언어',
    'title-detail': '상세',
    'title-welcome_to_use': '사용을 환영합니다',

    //tabBar
    'tabbar-market': '시세',
    'tabbar-order': '주문',
    'tabbar-news': '뉴스',
    'tabbar-mine': '마이페이지',
    'public-night_mode': '야간 모드',
    'public-noRecord': '데이터 없음',
    'public-text-cancel': '취소',
    'public-text-confirm': '확인',
    'public-text-balance': '잔액',
    'public-text-date': '시간',
    'public-text-completed': '완료',
    'public-text-invalid': '무효',
    'public-text-unchecked': '미검토',
    'public-text-remarks': '비고',
    'public-text-loading': '로딩 중',
    'public-text-loose-to-refresh': '당겨서 새로 고침...',
    'public-text-buy': '구매',
    'public-text-sell': '판매',
    'public-text-close': '마감',
    'public-text-lots_1': '계약',
    'public-text-lots': '계약 수',
    'public-text-available': '사용 가능',
    'public-text-freeze': '동결',
    'public-text-scroll_down_refresh': '아래로 스와이프하여 새로 고침...',
    'public-btn-submit': '제출',
    'public-btn-add': '추가',
    'public-btn-copy': '복사',
    'public-btn-delete': '삭제',
    'public-btn-copyLink': '초대 링크 복사',
    'public-btn-send': '전송',
    'public-btn-download': '다운로드',
    'public-btn-resetPassword': '비밀번호 재설정',
    'public-btn-confirmation': '확인',
    'public-btn-accept': '수락',
    'public-enterEmail': '이메일 주소를 입력하세요',
    'public-password': '비밀번호',
    'public-retypePassword': '비밀번호를 다시 입력하세요',
    'public-inviteCode': '초대 코드',
    'public-verifyCode': '인증 코드',
    'login-newUser': '새 사용자?',
    'login-joinNow': '지금 가입하기',
    'login-forgotPassword': '비밀번호를 잊으셨나요?',
    'login-btn-login': '로그인',
    'login-btn-demoAcc': '계좌 개설 없이 즉시 데모 계좌로 거래를 시작하세요!',
    'login-enterEmail': '이메일 주소를 입력하세요',
    'login-enterPassword': '비밀번호를 입력하세요',
    'regsiter-btn-register': '가입',
    'regsiter-enterInviteCode': '초대 코드를 입력하세요',
    'regsiter-enterVerityCode': '인증 코드를 입력하세요',
    'logout-title': '로그아웃',
    'systemHint-reset_successful': '리셋 성공',
    'systemHint-incorrectVerityCode': '인증 코드가 잘못되었습니다',
    'systemHint-passwordsDoNotMatch': '비밀번호가 일치하지 않습니다',
    'systemHint-password_error': '비밀번호 오류',
    'systemHint-login_connot_find_user': '사용자를 찾을 수 없습니다',
    'systemHint-copySuccess': '복사 성공',
    'systemHint-plsCompleted': '모든 항목을 입력해 주세요',
    'systemHint-pwdInconsistent': '비밀번호가 일치하지 않습니다',
    'systemHint-incorrectVerifyCode': '인증 코드가 잘못되었습니다',
    'systemHint-changeSuccess': '변경 성공',
    'systemHint-plsSubmit': '모든 정보를 제출해 주세요',
    'systemHint-binb-bank-not-open-yet': '이 통화의 출금은 아직 개방되지 않았습니다',
    'systemHint-plsFillIn': '모든 항목을 입력해 주세요',
    'systemHint-requestSuccessful': '요청 성공',
    'systemHint-operation_successful': '작업 성공',
    'systemHint-set_successful': '설정 성공',
    'systemHint-missing_params-or-val-error': '파라미터가 누락되었거나 값 오류',
    'systemHint-limit_trade_price_must_greater': '제한 거래 가격은 0보다 커야 합니다',
    'systemHint-buy-stop_loss_price_higher_than_opening_price': '구매(롱) 손절가가 개장가를 초과해서는 안 됩니다',
    'systemHint-buy-stop_profit_price_higher_than_opening_price': '구매(롱) 이익 실현가가 개장가를 초과해서는 안 됩니다',
    'systemHint-sell-stop_loss_price_lower_than_opening_price': '판매(숏) 손절가가 개장가를 하회해서는 안 됩니다',
    'systemHint-sell-stop_profit_price_lower_than_opening_price': '판매(숏) 이익 실현가가 개장가를 하회해서는 안 됩니다',
    'systemHint-code_send_succesful': '인증 코드가 전송되었습니다',
    'systemHint-pls_register_login_real_account': '실제 계좌에 등록하거나 로그인해 주세요',
    'systemHint-successful_exit': '로그아웃 성공',
    'popupHint-set_up_collection_account': '먼저 수금 계좌를 설정해 주세요',
    'popupHint-confirm_to_delete': '정말로 삭제하시겠습니까?',
    'popupHint-whether_return_it': '반환하시겠습니까?',
    'mine-availableAssets': '사용 가능한 자산',
    'mine-account_details': '계좌 상세',
    'mine-deposit': '입금',
    'mine-withdraw': '출금',
    'mine-billDetails': '청구서 상세',
    'mine-demo_return_simulated_account': '데모 계좌 리셋',
    'mine-wallet': '지갑',
    'mine-verifyName': '본인 인증',
    'mine-inviteFriend': '친구 초대',
    'mine-changePassword': '비밀번호 변경',
    'mine-onlineSerive': '고객 지원',
    'mine-complaintEmail': '불만 이메일',
    'mine-announcement': '공지사항',
    'mine-establishment': '설정',
    'mine-other': '기타',
    'mine-change_theme': '테마 변경',
    'dialog-title': '알림',
    'dialog-logout-conetnt': '정말로 로그아웃하시겠습니까?',
    'announce-text': '정책상의 이유로 현재 북한, 이스라엘, 중국, 바누아투, 쿠바에 대한 서비스를 제공하지 않습니다.',
    'announce-text-demo': '（데모는 테스트 용도만 사용됩니다）',
    'verify-text-upload': '여권/신분증의 앞면과 뒷면을 업로드 해주세요',
    'verify-front': '앞면',
    'verify-front-desc': '여권/신분증의 앞면을 업로드',
    'verify-back': '뒷면',
    'verify-back-desc': '여권/신분증의 뒷면을 업로드',
    'verify-text-info': '개인 정보를 입력해 주세요',
    'verify-name': '이름',
    'verify-enterName': '이름을 입력해 주세요',
    'verify-passportId': '여권/신분증 번호',
    'verify-enterPassportId': '여권/신분증 번호를 입력해 주세요',
    'verify-infoAuth': '검토 중',
    'wallet-bind-bank': '은행 카드 연결',
    'wallet-bind-currency-address': '암호화폐 주소 연결',
    'bind-bank-name': '은행 이름',
    'bind-bank-address': '출금 주소',
    'bind-bank-swift': 'SWIFT',
    'bind-bank-payee': '수취인',
    'bind-bank-payee-account': '수취인 계좌',
    'bind-currency': '통화',
    'bind-wallet-address': '지갑 주소',
    'deposit-digitai-currency': '암호화폐',
    'deposit-bank-card': '은행 카드',
    'deposit-select_network': '네트워크 선택',
    'deposit-coin-number': '입금 수량',
    'deposit-coin-placeholder': '입금할 코인 수량을 입력해 주세요',
    'doposit-upload-cert': '증명서 업로드',
    'deposit-record': '입금 기록',
    'deposit-recharge_amount': '입금액',
    'deposit-recharge-placeholder': '입금액을 입력해 주세요',
    'deposit-recharge_type': '유형',
    'deposit-recharge_unit': '단위',
    'deposit-recharge_status': '상태',
    'deposit-receipt_currency': '수취 통화',
    'withdraw-coin_address': '출금 주소',
    'withdraw-coin_amount': '수량',
    'withdraw-handling_fee': '수수료',
    'withdraw-expected': '예상 입금 금액',
    'withdraw-btn-submit': '출금 제출',
    'withdraw-coin_withdrawal_record': '출금 기록',
    'withdraw-num_of_receipt': '수취 수량',
    'withdraw-select_addess': '출금 주소를 선택해 주세요',
    'order-position_holding': '포지션 보유',
    'order-pending_orders': '미체결 주문',
    'order-history': '내역',
    'order-profit_loss': '총 수익/손실',
    'order-current_margin': '현재 마진',
    'order-risk_rate': '위험 비율',
    'order-order_id': '주문 ID',
    'order-total_profit_loss': '총 수익/손실',
    'order-order_details': '주문 상세',
    'order-margin': '마진',
    'order-take_profit': '이익 실현',
    'order-set_loss': '손절',
    'order-set_TPSL': 'TP/SL 수정',
    'order-close_position': '포지션 종료',
    'order-cancel_order': '주문 취소',
    'order-select_period': '기간 선택',
    'order-btn_rise': '상승',
    'order-btn_fall': '하락',
    'order-estimated_return': '예상 수익',
    'order-purchase_amount': '구매 금액',
    'search-search_product': '상품 검색',
    'search-search_result': '검색 결과',
    'trade-market_price': '시장 가격',
    'trade-price': '가격',
    'trade-quick_trade': '즉시 계약',
    'trade-please_enter_price': '가격을 입력해 주세요',
    'trade-each_lots': '계약당',
    'trade-estimated_handling_fee': '예상 수수료',
    'trade-estimated_margin': '예상 마진',
    'trade-lots_text': '구매 수량 (스텝: 0.001)',
    'trade-order_page': '주문 페이지',
    'trade-your_order_has_been_confirmed': '주문이 확인되었습니다',
    'trade-multiplier': '배수',
    'accHistory-financial_records': '재무 기록',
    'accHistory-records': '기록',

     'Res_Msg1': '이메일을 입력하세요',
    'Res_Msg2': '이메일 형식이 잘못되었습니다',
    'Res_Msg3': '비밀번호를 입력하세요',
    'Res_Msg4': '비밀번호와 확인 비밀번호가 일치하지 않습니다',
    'Res_Msg5': '비밀번호는 최소 6자 이상이어야 합니다',
    'Res_Msg6': '인증 코드가 잘못되었습니다',
    'Res_Msg7': '인증 코드를 입력하세요',
    'Res_su': '등록 성공',
    'Login_su': '로그인 성공',
    'Loading': '더 이상 데이터가 없습니다',
    'SDealCont_TopName': '배송',
    'SDealCont_L1': '구매 가격',
    'SDealCont_L2': '현재 가격',
    'SDealCont_L3': '방향',
    'SDealCont_L4': '수량',
    'SDealCont_L5': '배송 시간',
    'SDealCont_btn': '닫기',
    'SDealCont_L6': '매수',
    'SDealCont_L7': '매도',
    'SDealCont_L8': '구매',
    'SDealCont_L9': '판매',
    'verify-verified': '검증 성공',
    'verify-notVerified': '검증 실패',
    "加载中...": "로딩 중...",
    "分时":"분시",
    "登入密碼": "로그인 비밀번호를 입력해주세요",
    "登入密碼不能为空": "로그인 비밀번호는 비워 둘 수 없습니다"

}
