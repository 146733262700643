<template>
    <div id="trade">
        <div v-if="curTab !== 2">
            <div class="v-text-12 v-border-0 border-b v-pb-16">
                <div v-if="curTab == 1" class="flex justify-between items-center v-my-16 v-border-0 border-b">
                    <div class="text-color-6" style="min-width: 16vw">
                        {{ $t("trade-price") }}
                    </div>
                    <van-field v-model="priceVal" :placeholder="$t('trade-please_enter_price')"
                        style="font-size: 3.666vw; padding: 3vw 0" class="bg-main" input-align="right" type="number" />
                </div>

                <div class="flex justify-between items-center v-mt-14">
                    <span class="text-color-6">{{ $t("trade-multiplier") }}</span>
                    <div class="mult-picker flex items-center justify-end" @click="showMultiplierPicker = true">
                        <span class="k-text v-text-14 flex-1 text-center">{{
                            multiplierVal
                            }}</span>
                        <van-icon name="arrow" class="icon-right" style="margin-right: 1.6vw; font-size: 1.2rem" />
                    </div>
                    <van-popup v-model:show="showMultiplierPicker" round position="bottom">
                        <van-picker :columns="multiplierList" :cancel-button-text="$t('public-text-cancel')"
                            :confirm-button-text="$t('public-text-confirm')" @cancel="showMultiplierPicker = false"
                            @confirm="onMultiplierConfirm" />
                    </van-popup>
                </div>

                <div class="flex justify-between items-center v-mt-14">
                    <van-checkbox label-position="left" v-model="lossChecked" shape="square">
                        <span class="text-color-6">{{ $t("order-set_loss") }}</span>
                    </van-checkbox>
                    <van-stepper v-model="lossVal" :min="0" input-width="28.66vw" button-size="8vw"
                        :disabled="!lossChecked" />
                </div>
                <div class="flex justify-between items-center v-mt-14">
                    <van-checkbox label-position="left" v-model="profitChecked" shape="square">
                        <span class="text-color-6">{{ $t("order-take_profit") }}</span>
                    </van-checkbox>
                    <van-stepper v-model="profitVal" :min="0" input-width="28.66vw" button-size="8vw"
                        :disabled="!profitChecked" />
                </div>
                <div class="flex justify-between items-center v-mt-14">
                    <span class="text-color-6">{{ $t("trade-lots_text") }}</span>
                    <van-stepper v-model="lotsVal" :min="0.001" :default-value="0.001" :step="0.001"
                        input-width="28.66vw" button-size="8vw" @blur="handleChange" />
                </div>
            </div>
            <div class="v-text-12 v-py-16"
                v-if="$store.getters.GetInfoData.token != '' && $store.getters.GetInfoData.token != null">
                <div class="flex items-center justify-between v-mt-14">
                    <div class="text-color-6">{{ $t("trade-each_lots") }}</div>
                    <div class="k-text">
                        {{
                        `1 ${$t("public-text-lots_1")} = ${ContData.amount || 0}`
                        }} {{ props.symbolData.symbol }}
                    </div>
                </div>
                <div class="flex items-center justify-between v-mt-14">
                    <div class="text-color-6">
                        {{ $t("trade-estimated_handling_fee") }}
                    </div>
                    <div class="k-text">{{ Number(charge || 0) }}</div>
                </div>
                <div class="flex items-center justify-between v-mt-14">
                    <div class="text-color-6">{{ $t("trade-estimated_margin") }}</div>
                    <div class="k-text">{{ marginVal || 0 }}</div>
                </div>
                <div class="flex items-center justify-between v-mt-14">
                    <div class="text-color-6">{{ $t("public-text-balance") }}</div>
                    <div class="k-text">{{ userInfo.balance }}</div>
                </div>
            </div>

            <!-- 按钮：Buy & Sell -->
            <div class="flex text-center"
                v-if="$store.getters.GetInfoData.token != '' && $store.getters.GetInfoData.token != null">
                <van-button round color="#fff" style="margin-right: 2vw"
                    class="v-h-36 flex-1 bg-kline-up v-rounded-xl v-border-0 v-text-14" :disabled="status === '1'"
                    @click="confirmBuyBtn">{{ $t("public-text-buy") }}
                </van-button>
                <van-button round color="#fff" style="margin-left: 2vw"
                    class="v-h-36 flex-1 bg-kline-down v-rounded-xl v-border-0 v-text-14" :disabled="status === '1'"
                    @click="confirmSellBtn">{{ $t("public-text-sell") }}
                </van-button>
            </div>
        </div>

        <div v-if="curTab == 2">
            <div class="v-mt-16 v-text-12 v-pb-16"
                v-if="$store.getters.GetInfoData.token != '' && $store.getters.GetInfoData.token != null">
                <div class="text-color-6">{{ $t("order-select_period") }}</div>
                <div class="flex justify-between items-center">
                    <!-- 选择周期 -->
                    <div class="v-mt-4 base-shadow">
                        <van-field v-model="periodVal" readonly left-icon="clock" right-icon="arrow-down"
                            input-align="center" class="full-border"
                            style="max-width: 28vw; width: fit-content; border-radius: 2.22vw" :placeholder="periodVal"
                            @click="showPeriodPicker = true" />
                        <van-popup v-model:show="showPeriodPicker" round position="bottom">
                            <van-picker round :columns="periodList" :cancel-button-text="$t('public-text-cancel')"
                                :confirm-button-text="$t('public-text-confirm')" @cancel="showPeriodPicker = false"
                                @confirm="periodConfirm" />
                        </van-popup>
                    </div>
                    <!-- 按钮 -->
                    <div class="btcButton flex v-text-16 text-center overflow-hidden v-rounded-8">
                        <div class="v-py-10 v-pr-6 cus-button button-1"
                            :class="selectedBtn == 0 ? 'active' : 'inactive'" @click="selectBtn(0)">
                            {{ $t("order-btn_rise") }}
                        </div>
                        <div class="v-py-10 v-pl-6 cus-button button-2"
                            :class="selectedBtn == 1 ? 'active' : 'inactive'" @click="selectBtn(1)">
                            {{ $t("order-btn_fall") }}
                        </div>
                    </div>
                </div>

                <div class="v-mt-8">
                    <div class="v-text-14 k-text">
                        {{ $t("order-estimated_return") }}: {{ returnValue }}%
                    </div>
                </div>

                <div class="v-mt-16 text-color-6">
                    {{ $t("order-purchase_amount") }}
                </div>
                <div class="flex justify-start items-center">
                    <!-- 选择金币 -->
                    <div class="v-mt-4 base-shadow v-mr-4">
                        <van-field v-model="coinVal" readonly left-icon="balance-coin" input-align="center"
                            class="full-border" style="width: 28vw; border-radius: 2.22vw" :placeholder="coinVal" />
                    </div>
                    <div class="v-mt-4 base-shadow v-w-full">
                        <van-field v-model="inputCoinVal" type="number" :placeholder="`>= 100 ` + coinVal"
                            class="full-border" style="height: inherit; border-radius: 2.22vw" />
                    </div>
                </div>
                <div class="v-mt-8">
                    <div class="v-text-14 k-text">
                        {{ $t("public-text-balance") }}: {{ userInfo.balance }}
                    </div>
                </div>

                <div class="v-w-full v-mt-22">
                    <van-button block class="submit-btn v-border-0" :disabled="status === '1'" @click="submitBtn">
                        {{ $t("public-btn-submit") }}
                    </van-button>
                </div>
            </div>
        </div>

        <!-- 订单确认弹窗 -->
        <van-popup v-model:show="showConfirmPopup" position="bottom" round closeable style="height: 60%;">
            <div class="flex flex-col items-center">
                <div
                    class="flex justify-center v-border-4 border-kline-up v-rounded-half v-w-100 v-h-100 mx-auto items-center v-mt-36 v-pt-6">
                    <van-icon name="success" size="5rem" class="text-kline-up" />
                </div>
                <div class="k-text v-text-base v-my-20">
                    {{ $t("trade-your_order_has_been_confirmed") }}
                </div>
                <div class="flex justify-center w-[80%] v-h-40 mb-[14px]">
                    <van-button round class="btn-blue v-border-0 flex-1 v-h-40 v-text-14" @click="closePopup">
                        {{ $t("public-btn-confirmation") }}
                    </van-button>
                </div>
                <div class="flex justify-center">
                    <van-button icon="arrow" icon-position="right" class="text-kline-up" style="
                            border: 0 !important;
                            font-size: 3.73333vw;
                            margin-bottom: 2vw;
                        " @click="navigateTo">{{ $t("trade-order_page") }}
                    </van-button>
                </div>
            </div>
        </van-popup>

        <!-- 秒合约弹窗 -->
        <van-popup v-model:show="SDealContFlag" style="width: 80%; border-radius: 8px">
            <div>
                <div style="text-align: right; padding-right: 10px; padding-top: 10px">
                    <van-icon name="cross" @click="SDealContFlag = false" />
                </div>
                <div style="
                    text-align: left;
                    padding-left: 10px;
                    font-weight: bold;
                    padding-bottom: 16px;
                    border-bottom: 1px solid #1f1f1f;
                    margin-bottom: 10px;
                ">
                    {{ props.symbolData.symbol }} {{ $t("SDealCont_TopName") }}
                </div>
                <div style="text-align: center; padding-top: 10px">
                    <van-circle v-model:current-rate="currentRate" :rate="currrate" :speed="100" :text="currtext"
                        layer-color="#edf2fa" />
                </div>
                <div style="padding: 10px; display: flex; justify-content: space-between">
                    <div>
                        {{ $t("SDealCont_L1") }}
                    </div>
                    <div>
                        <!-- 购买价格 -->
                        {{ TimeArr.open_price || 0 }}
                    </div>
                </div>
                <div style="padding: 10px; display: flex; justify-content: space-between">
                    <div>
                        {{ $t("SDealCont_L2") }}
                    </div>
                    <div :style="{ color: TimeArr.type == 1 ? '#0166fc' : 'red' }">
                        <!-- 现价 -->
                        {{ NowPrice }}
                    </div>
                </div>
                <div style="padding: 10px; display: flex; justify-content: space-between">
                    <div>
                        {{ $t("SDealCont_L3") }}
                    </div>
                    <div>
                        <span v-if="TimeArr.type == 1" style="color: #0166fc">{{
                            $t("SDealCont_L6")
                            }}</span>
                        <span v-if="TimeArr.type == 2" style="color: red">{{
                            $t("SDealCont_L7")
                            }}</span>
                    </div>
                </div>
                <div style="padding: 10px; display: flex; justify-content: space-between">
                    <div>
                        {{ $t("SDealCont_L4") }}
                    </div>
                    <div>
                        {{ Number(TimeArr.money).toFixed(2) }}
                    </div>
                </div>
                <div style="padding: 10px; display: flex; justify-content: space-between">
                    <div>
                        {{ $t("SDealCont_L5") }}
                    </div>
                    <div>
                        {{ $formatTimestamp(TimeArr.close_time) }}
                    </div>
                </div>
                <div style="display: flex; justify-content: center; margin: 30px 0">
                    <div style="
              width: 80%;
              background: #464b61;
              text-align: center;
              padding: 14px 0;
              border-radius: 6px;
              color: white;
            " @click="SDealContFlag = false">
                        {{ $t("SDealCont_btn") }}
                    </div>
                </div>
            </div>
        </van-popup>
    </div>
</template>

<script setup>
import { ref, onMounted, computed, watch, watchEffect, onUnmounted, nextTick } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { store } from "@/assets/store/data.js";
import { showToast } from "/node_modules/vant";

const props = defineProps({
  curTab: {
    type: Number,
    default: 0,
  },
  symbolData: {
    type: Object,
  },
  symbolName:{
    type:Object
  }
});
const storeA = useStore();
const { t } = useI18n();
const router = useRouter();
const userInfo = computed(() => storeA.getters["GetInfoData"]);
// curTab: 1
const priceVal = ref(null);
// curTab: 0 & 1
const lossChecked = ref(false);
const lossVal = ref(0);
const profitChecked = ref(false);
const profitVal = ref(0);
const lotsVal = ref(0.001);
const showConfirmPopup = ref(false);
const multiplierList = ref([]);
const multiplierVal = ref();
const showMultiplierPicker = ref(false);
// curTab: 2
const periodList = ref([]);
const periodVal = ref();
const showPeriodPicker = ref(false);
const selectedBtn = ref(0);
const returnValue = ref(0);
const coinVal = ref("USDT");
const inputCoinVal = ref("");
const ContData = ref({});

const SDealContFlag = ref(false);
const currentRate = ref(100);
const currrate = ref();
const currtext = ref("");

const NowPrice = ref('');

// 提示
const showMessage = (icon, message) => {
  showToast({
    type: icon.toLowerCase(),
    message,
    duration: 1500,
  });
};

onMounted(async () => {
  lossVal.value = 0;
  profitVal.value = 0;
  await storeA.dispatch("GetUserData");
  await getCurrData();
});

async function getCurrData(){
    if (props.symbolData.symbol == '' || props.symbolData.symbol == null) return;

    ContData.value = await storeA.dispatch("GetContData", {
        data: { ticker: props.symbolData.symbol },
    });
    ContData.value.leverage.map((item) => {
        multiplierList.value.push({ text: item.lever_rate, value: item.id });
    });

    ContData.value.seconds.map((item) => {
        periodList.value.push({
            text: item.time_num + item.time_unit,
            value: item.profit_ratio,
        });
    });
    periodVal.value = periodList.value[0].text;
    returnValue.value = periodList.value[0].value;
    multiplierVal.value = multiplierList.value[0].text;
}

//计算Lots增减对应的Margin
const marginVal = computed(() => {
    if (props.curTab == 1){
        return parseFloat(
            lotsVal.value * ContData.value.amount
        );
    }else{
        return parseFloat(
            lotsVal.value * ContData.value.amount
        );
    }
});

const charge = computed(() => {
    return Number(marginVal.value * (ContData.value.lever_rate / 100)) 
})

// 监听props变化，重置数据
watch(
  () => props.curTab,
  () => {
    priceVal.value = "";
    lossChecked.value = false;
    lossVal.value = 0;
    profitChecked.value = false;
    profitVal.value = 0;
    lotsVal.value = 0.001;
  }
);

watch(() => props.symbolName,async() =>{
    await getCurrData();
})

let SecFlag = false;

watch(() => props.symbolData, async () => {
    if (SecFlag){
        NowPrice.value = props.symbolData.close;
    }
})

function handleChange(){
    lotsVal.value = parseFloat(lotsVal.value).toFixed(3);
}

const navigateTo = () => {
  router.push({ name: "OrderIndex" });
};

const closePopup = () => {
  showConfirmPopup.value = false;
};

// 买入
const confirmBuyBtn = async () => {
  let data;
  if (props.curTab == 0) {
    data = {
      ticker: props.symbolData.symbol,
        amount: Number(lotsVal.value) ,
      lever_rate: multiplierVal.value,
      direction: "buy",
      price_type: "opponent",
      stop_loss_price: lossVal.value,
      stop_win_price: profitVal.value,
    };
  } else if (props.curTab == 1) {
      if (!priceVal.value) {
          showMessage("fail", t("systemHint-missing_params-or-val-error"))
          return
      }
    data = {
      ticker: props.symbolData.symbol,
        amount: Number(lotsVal.value) ,
      lever_rate: multiplierVal.value,
      direction: "buy",
      price_type: "limit",
      price: priceVal.value,
      stop_loss_price: lossVal.value,
      stop_win_price: profitVal.value,
    };
  }

  await storeA.dispatch("DealCont", { data });
    showConfirmPopup.value = true;
  await storeA.dispatch("GetUserData");
};

// 卖出
const confirmSellBtn = async () => {
  let data;
  if (props.curTab == 0) {
    data = {
      ticker: props.symbolData.symbol,
      amount: Number(lotsVal.value) ,
      lever_rate: multiplierVal.value,
      direction: "sell",
      price_type: "opponent",
      stop_loss_price: lossVal.value,
      stop_win_price: profitVal.value,
    };
  } else if (props.curTab == 1) {
      if (!priceVal.value) {
          showMessage("fail", t("systemHint-missing_params-or-val-error"))
          return
      }
    data = {
      ticker: props.symbolData.symbol,
      amount: Number(lotsVal.value) ,
      lever_rate: multiplierVal.value,
      direction: "sell",
      price_type: "limit",
      price: priceVal.value,
      stop_loss_price: lossVal.value,
      stop_win_price: profitVal.value,
    };
  }

  await storeA.dispatch("DealCont", { data });

    showConfirmPopup.value = true;

  await storeA.dispatch("GetUserData");
};

const TimeArr = ref();

// 秒合约
const submitBtn = async () => {
  if (inputCoinVal.value == "" || inputCoinVal.value == null) {
    showMessage("fail", t("systemHint-plsSubmit"));
    return;
  }

  let id;
  ContData.value.seconds.map((item) => {
    if (item.time_num + item.time_unit === periodVal.value) {
      id = item.para_id;
    }
  });
  const data = {
    ticker: props.symbolData.symbol,
    amount: inputCoinVal.value,
    para_id: id,
    direction: selectedBtn.value == 0 ? "buy" : "sell",
  };
  NowPrice.value = props.symbolData.close;
  const Order_ID = await storeA.dispatch("SDealCont", { data });

  showMessage("success");
  await storeA.dispatch("GetUserData");
  TimeArr.value = await storeA.dispatch("GetSDealOrder", {
    data: { order_no: Order_ID.order_no },
  });
    SDealContFlag.value = true;
    SecFlag = true;
    CutTime(TimeArr.value);
};

const InterId = ref();
// 合约倒计时
async function CutTime(TimeArr) {
  let time_count = Number(TimeArr.seconds);
  currtext.value = time_count + TimeArr.time_unit;
  InterId.value = setInterval(async () => {
    if (time_count == 0) {
        SecFlag = false;
        await storeA.dispatch("GetUserData");
        const profitsArr = await storeA.dispatch('GetSDealOrder', { data: { order_no: TimeArr.order_no }})
        console.log(profitsArr)
        currtext.value = Number(profitsArr.profits).toFixed(2);
        NowPrice.value = props.symbolData.close;
        clearInterval(InterId.value);
    } else {
      time_count--;
      currtext.value = time_count + TimeArr.time_unit;
      currrate.value =
        (1 - (Number(TimeArr.seconds) - time_count) / Number(TimeArr.seconds)) *
        100;
    }
  }, 1000);
  // periodVal
  // currtext.value =
}

// onUnmounted(() => {
//   if (InterId.value) {
//     clearInterval(InterId.value);
//   }
// });

const onMultiplierConfirm = (val) => {
  multiplierVal.value = val.selectedOptions[0].text;
  showMultiplierPicker.value = false;
};

const periodConfirm = (val) => {
  periodVal.value = val.selectedOptions[0].text;
  returnValue.value = val.selectedOptions[0].value;
  showPeriodPicker.value = false;
};

const selectBtn = (idx) => {
  selectedBtn.value = idx;
};
</script>

<style scoped>
:deep(.van-field__right-icon > i) {
  font-size: 3.222vw !important;
}

/* :deep(.van-cell.van-field) {
    padding: 2vw !important;
} */

.cus-button {
  position: relative;
  width: 25.66667vw;
  height: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.button-1 {
  clip-path: polygon(0 0, 100% 0, 80% 100%, 0 100%);
  margin-right: -1.666vw;
}

.button-2 {
  clip-path: polygon(20% 0, 100% 0, 100% 100%, 0 100%);
  margin-left: -1.666vw;
}

.mult-picker {
  width: 45.86667vw;
  height: 7.46667vw;
  display: flex;
  align-items: center;
  position: relative;
}

:deep(.van-field__body) {
  display: flex;
  justify-content: end;
}

.van-picker {
  margin-bottom: 30px;
}

</style>
